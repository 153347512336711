<template>
<div>
<validation-observer ref="CampaignRules">
  <b-form>
    <b-card
      

    >
        <b-tabs pills class="mb-2">
            <b-tab active>
                <template #title>
                <feather-icon
                    icon="FileIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Template</span>
                </template>
                <b-row>
                  <b-col md="12" >
                       <b-form-group>
                        <h6>Title</h6>
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="title"
                                >
                            <b-form-input v-model="input.title" />
                                <small class="text-danger">{{ errors[0] }}</small>
                             </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" >
                      <b-form-group>
                        <h6>scheduled</h6>
                         
                        
                          <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="scheduled"
                                >
                            <v-select
                            placeholder="Select Type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="Type"
                            v-model="input.scheduled"
                            :options="scheduleds"
                          
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        
                    </b-col>
                    <b-col md="6" >
                      <b-form-group>
                        <h6>From</h6>
                         
                        
                          <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="from"
                                >
                              <b-form-input  v-model="input.frommail" placeholder="From" />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        
                    </b-col>
                    <b-col md="6" >
                      <b-form-group>
                        <h6>CC</h6>
                         
                        
                          <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="cc"
                                >
                              <b-form-input  v-model="input.cc" placeholder="CC" />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        
                    </b-col>
                    <b-col md="12" v-if="input.scheduled == 'Scheduled'">
                        <b-form-group>
                          <h6>Time picker</h6>
                           <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="date_sent"
                                >
                          <flat-pickr
                          v-model="input.date_sent"
                          class="form-control"
                          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                        />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        
                        </b-form-group>
                    </b-col>
                    <b-col md="12" >
                      <b-form-group>
                        <h6>Template</h6>
                        
                           <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="template"
                                >
                            <v-select
                            placeholder="select template"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            v-model="input.template"
                            :options="templates"
                            @input="changeTemplate"
                            />
                             <small class="text-danger">{{ errors[0] }}</small>
                             </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" >
                       <b-form-group>
                        <h6>Subject</h6>
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="subject"
                                >
                            <b-form-input v-model="input.subject" />
                                <small class="text-danger">{{ errors[0] }}</small>
                             </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" class="q-message" >
                      <b-form-group>
                        <h6>Message</h6>
                      
                           <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="message"
                                >
                            <quill-editor
                            style="height: 350px"
                                v-model="input.message"
                            
                            />
                             <small class="text-danger">{{ errors[0] }}</small>
                             </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab>
                <template #title>
                    <feather-icon
                    icon="UsersIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Group Email</span>
                    
                </template>
                <b-row>
                    <b-col md="6">
                        <h6 class="text-primary font-weight-bold mb-2">
              List Groups
            </h6>

            <!-- draggable -->
            <draggable
              :list="groupsMails"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(listItem, index) in groupsMails"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <b-avatar :text="listItem.title.slice(0,2)" />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.title }}
                    </b-card-text>
                    <small>{{ listItem.title }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
                    </b-col>
                    <b-col md="6">
                        <h6 class="text-primary font-weight-bold mb-2">
              Selectd
            </h6>

            <!-- draggable -->
            <draggable
              :list="groupsSelected"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(listItem, index) in groupsSelected"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <b-avatar :text="listItem.title.slice(0,2)" />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.title }}
                    </b-card-text>
                    <small>{{ listItem.title }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab>
                <template #title>
                    <feather-icon
                    icon="PackageIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Subscribe</span>
                    
                </template>
            <b-row>
                    <b-col md="6">
                        <h6 class="text-primary font-weight-bold mb-2">
              List Subscriber
            </h6>

            <!-- draggable -->
            <draggable
              :list="subscribes"
              tag="ul"
              group="subsc"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(listItem, index) in subscribes"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <b-avatar :text="listItem.title.slice(0,2)" />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.title }}
                    </b-card-text>
                    <small>{{ listItem.title }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
                    </b-col>
                    <b-col md="6">
                        <h6 class="text-primary font-weight-bold mb-2">
              Selectd
            </h6>

            <!-- draggable -->
            <draggable
              :list="subscribeSelected"
              tag="ul"
              group="subsc"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(item, index) in subscribeSelected"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <b-avatar :text="item.title.slice(0,2)" />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ item.title }}
                    </b-card-text>
                    <small>{{ item.title }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
                    </b-col>
                </b-row>
            </b-tab>
            <!--<b-tab>
                <template #title>
                    <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Users</span>
                    
                </template>
            User
            </b-tab>--> 


        </b-tabs>   
         <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
        >
            Submit
        </b-button>
    </b-card>
  </b-form>
</validation-observer>
</div>   
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,BCardHeader,BCardBody,
  BTab, BTabs, BFormGroup, BForm, BCardTitle,BListGroupItem,BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'

//import useSendMail from './useSendMail';
import crmStoreModule from '../crmStoreModule'
import axios from '@axios'

//validator
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/* eslint-disable global-require */

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import router from '@/router'
import { quillEditor } from 'vue-quill-editor'

import moment from 'moment'

import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

export default {
    components: {
        //useTemplateList,
        crmStoreModule,
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,BFormDatepicker,
        vSelect,
        BCardHeader,BCardBody,
        BCardActions,
        BTab, BTabs,
        BFormGroup, BForm, BCardTitle,
        quillEditor,
        BListGroupItem,
        Prism,
        draggable,
        BCardText,
        flatPickr,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
   
     
      Ripple,
    },
    data(){
        return {
            templates:[],
            userData: JSON.parse(localStorage.getItem('userData')),
            input:{
                title:'',
                subject:'',
                frommail:'',
                cc:'',
                scheduled:'Now',
                date_sent:'',
                template:'',
                message:'',
                status:'created',
                created_by:null
            },
            userData: JSON.parse(localStorage.getItem('userData')),
            scheduleds:['Now','Scheduled'],
            groupsMails:[ ],
            subscribes:[],
            subscribeSelected:[],
            groupsSelected:[ ],
            list1: [
                {
                name: 'Paz Joya',
                email: 'girliness@spotlike.co.uk',
                },
                {
                name: 'Sunshine Cose',
                email: 'executrixship@equisized.edu',
                },
                {
                name: 'Alba Dobbin',
                email: 'bidding@demibob.or',
                },
                {
                name: 'Marlin Hinchee',
                email: 'preholding@scuffly.co.uk',
                },
            ],
            list2: [
                {
                name: 'Leia Atienza',
                email: 'unmeasurableness@interlamellar.co.uk',
                },
                {
                name: 'Lashawna Vaudrainm',
                email: 'soaking@khubber.com',
                },
                {
                name: 'Liliana Henscheid',
                email: 'lecideine@turndown.org',
                },
                {
                name: 'Keven Kolter',
                email: 'nontenure@anglicanum.co.uk',
                },
            ]
        }
    },
    setup() {
      
        const CRM_APP_STORE_MODULE_NAME = 'app-crm';
        if (!store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.registerModule(CRM_APP_STORE_MODULE_NAME, crmStoreModule);
        onUnmounted(() => {
            if (store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.unregisterModule(CRM_APP_STORE_MODULE_NAME)
        });
       
        
    },
    created(){
      this.input.frommail = this.userData.email
        this.getTemplates();
        this.getGroupMails();
        this.getSubscribes();
    },
    methods:{
        async validationForm(){
          this.$refs.CampaignRules.validate().then(async success => {
          
            if(success){
                
              if(this.subscribeSelected.length == 0 && this.groupsSelected.length == 0){
                this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Pelase select group email or subcriber.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                      },
                  });
                  return false;
                
              }
              if(this.input.scheduled == 'Now'){
                let date_sent = new Date();
                let sent_time = new Date();
              
                this.input.date_sent =  moment(String(date_sent)).format('YYYY-MM-DD hh:mm:ss');
                this.input.sent_time =  moment(String(sent_time)).format('YYYY-MM-DD hh:mm:ss');


              }else{
                this.input.date_sent += ':00';
              }
               
              this.input.created_by = this.userData.id;
              this.input.subscribeSelected = this.subscribeSelected;
              this.input.groupsSelected = this.groupsSelected;
              var data = JSON.stringify(this.input);
              let  res = await  axios.post('crm/v1/campaign-add',data);
              
              if(!res.data.success){
                console.log('false',res.data.message);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: res.data.message,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
              }else{
                console.log('true',res.data.message);
                this.$router.push('/crm/campaign', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `Done`,
                        icon: 'CoffeeIcon',                 
                        variant: 'success',
                        text: `You have successfully Add .`,
                        },
                    })
                });
                //crm/campaign

              }
               
  
            }
          });

        },
        async getSubscribes(){
           let params = { perpage:100,offset:0, created_by:this.userData.id};
          let  data = JSON.stringify(params);
         
          let subs = await axios.post('crm/v1/subscriber-list', data);
          console.log('subs',subs);
          this.subscribes = subs.data.data;

        },
        async getGroupMails(){
          let params = { limit:100,offset:0};
          let  data = JSON.stringify(params);
         
          let groups = await axios.post('groupmail/v1/lists', data);
         
          this.groupsMails = groups.data.data;
        },
        async getTemplates(){
            let params = { perpage: 100, offset:0};
             let  data = JSON.stringify(params);
           let templates =  await   axios.post('/crm/v1/template-list', data);
           this.templates  = templates.data.data;
            

        },
        changeTemplate(){
            this.input.message = this.input.template.message;
            console.log('input',this.input);
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
ul.list-group {
  border: 1px solid #ccc;
  padding: 20px;

}
.q-message{
    height: 450px;
}
</style>
